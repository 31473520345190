<template>
    <div id="Live" class="columns no-scroll">
        <div id="live-rooms" class="column  has-scroll">

        </div>
        <div id="live-main" class="column  no-scroll">
            <!--div id="live-overlay"  v-if="!selectedRoom" class="row chat-scroll"></div-->
            <div id="live-window" ref="mainLive" class="row  boot-row chat-scroll"
                 :class="{'has-quote' : hasLive}">
              <CameraPlayerMain></CameraPlayerMain>

            </div>
            <div id="live-chat"  class="row"
                 :class="{'has-quote' : hasLive}"
            >
            </div>
        </div>

    </div>
</template>
<script>
    // import $socket from '../socket'
    //import StreamMain from "../components/StreamCore/StreamMain";
    import CameraPlayerMain from "../components/MediaPlayers/CameraPlayerMain";

    import axios from "@/axios";
    import EventBus from '@/eventBus';
    import socket from "@/socket";

    export default {
      name: "Live",
      components:{
        CameraPlayerMain
      },
      data(){
        return{
          hasLive: true
        }
      },

      computed: {
        userServers() {
          return this.$store.getters.get_userServers;
        },
        userDataAfterLogIn() {
          return this.$store.getters.get_userDataAfterLogIn;
        },
        selectedServer() {
          return this.$store.getters.get_selectedServer;
        },
        selectedRoom() {
          return this.$store.getters.get_selectedRoom;
        },
        commentsDollar () {
          return require('@/assets/icons/comments-dollar-solid.svg');
        },
        selectRoom () {
              return require('@/assets/icons/grunge-arrow-white.png');
        },
      },
      created() {
        EventBus.$on('switchStream', () => {
          this.transferLive();
        });


      },
      mounted() {

      },
      methods: {
        transferLive() {
          let stream = document.getElementById('#streaming-tab');
          console.log(this.$refs, stream);

        }

      }

    }
</script>

<style>

    #live {
      display: inline-block;
      width: 100%;
      max-width: 100%;
      white-space: nowrap;
      position: relative;
      margin: 0;

      /**display: inline-flex;*/
    }

    #live-rooms {
      height: 100%;
      max-height: 100%;
      display: inline-block;
      background-color: #23272a;
      overflow: hidden;
      padding: 0.5em 0;
      width: 255px;  /** zeplin */
      max-width: 255px;
    }


    #live-main {
      height: 100%;
      max-height: 100%;
      display: inline-block;
      position: relative;
      padding: 0.75em 0.75em 0.75em;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
      background-color: #1d2023;
      width: calc(100% - 256px);
      max-width: calc(100% - 256px);
    }

    #live-overlay {
      height: 100%;
      max-height: 100%;
      display: inline-block;
      position: relative;
      padding: 0.75em 0.75em 1.25em;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
      background-color: #1d2023;
      width: 100%;
    }

    #chat-sidebar {
      display: inline-block;
      position: relative;
    }

    #live-window {
      /**height: 91%;
      max-height: 91%;*/
      padding: 1em 0;
      overflow: hidden;
      height: calc(100% - 75px );
      max-height: calc(100% - 75px );
      text-align: center;
      display: block;
    }

    #live-window .stream-container {

    }

    #chat-window.has-quote {
      height: calc(100% - 110px );
      max-height: calc(100% - 110px );
    }

    #live-chat {

    }

    .main-scroll,
    .has-scroll {
        overflow: auto;
        height: 100%;
        max-height: 100%;
    }

    .no-scroll {
        overflow: hidden;
        height: 100%;
        max-height: 100%;
    }

    .chat-scroll {
        padding: 0;
        margin: 0;
    }




    /*** responsive */

    @media (max-width: 1440px)  {

    }

    @media (max-width: 1200px)  {

    }

    @media (max-width: 992px)  {

    }


    @media (max-width: 792px)  {

    }

    @media (max-width: 768px)  {

    }

    @media (max-width: 480px)  {

    }






</style>
